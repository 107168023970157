import React from "react";

const TermsAndConditions = ({ darkTheme }) => {
  return (
    <div
      id="terms-policy"
      className="modal fade"
      role="dialog"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-lg modal-dialog-centered"
        role="document"
      >
        <div
          className={
            "modal-content " + (darkTheme ? "bg-dark-2 text-light" : "")
          }
        >
          <div className="modal-header">
            <h5 className={"modal-title " + (darkTheme ? "text-white" : "")}>
              Terms &amp; Policy
            </h5>
            <button
              type="button"
              className={"btn-close " + (darkTheme ? "btn-close-white" : "")}
              data-bs-dismiss="modal"
              aria-label="Close"
            />
          </div>
          <div className="modal-body p-4">
            <p className={"text-auto" + (darkTheme ? "text-white-50": "")}>
              {/**
               * We appreciate your visit to our website. Please take a moment to read and understand the following terms and policies that govern your use of our website.

Acceptance of Terms: By using our website, you acknowledge and agree to be bound by these terms and policies.

Privacy Policy: We value your privacy and are committed to protecting your personal information. Please review our Privacy Policy for detailed information on how we collect, use, and safeguard your data.

Intellectual Property: All content on our website, including but not limited to text, images, graphics, videos, and software, is the property of our company or our partners and is protected by intellectual property laws. You are not allowed to reproduce, distribute, modify, or create derivative works of our content without our prior written consent.

Use of Website: You are permitted to use our website for lawful purposes only. You are prohibited from using our website in any manner that violates applicable laws or infringes on the rights of others.

Links to Third-Party Sites: Our website may include links to third-party sites. We are not responsible for the content or practices of those sites and encourage you to review their terms and policies before using them.

Disclaimer of Warranties: Our website is provided on an "as is" basis, without any express or implied warranties. We do not guarantee that our website will be uninterrupted, error-free, or that any defects will be corrected.

               */}
              We appreciate your visit to our website. Please take a moment to read and understand the following terms and policies that govern your use of our website.
            </p>
            <h3 className={"mb-3 mt-4 mt-4 " + (darkTheme ? "text-white" : "")}>
            Acceptance of Terms
            </h3>
            <p>
            By using our website, you acknowledge and agree to be bound by these terms and policies.
            </p>
            <h5 className={"text-4 mt-4 " + (darkTheme ? "text-white" : "")}>
            Privacy Policy:
            </h5>
            <p>
            We value your privacy and are committed to protecting your personal information. Please review our Privacy Policy for detailed information on how we collect, use, and safeguard your data.
            </p>
            <h5 className={"text-4 mt-4 " + (darkTheme ? "text-white" : "")}>
            Intellectual Property:
            </h5>
            <p>
            All content on our website, including but not limited to text, images, graphics, videos, and software, is the property of our company or our partners and is protected by intellectual property laws. You are not allowed to reproduce, distribute, modify, or create derivative works of our content without our prior written consent.
            </p>
            <h5 className={"text-4 mt-4 " + (darkTheme ? "text-white" : "")}>
            Use of Website:
            </h5>
            <p>
            You are permitted to use our website for lawful purposes only. You are prohibited from using our website in any manner that violates applicable laws or infringes on the rights of others.
            </p>
            <h5 className={"text-4 mt-4 " + (darkTheme ? "text-white" : "")}>
            Links to Third-Party Sites:
            </h5>
            <p>
            Our website may include links to third-party sites. We are not responsible for the content or practices of those sites and encourage you to review their terms and policies before using them.
            </p>
            <h5 className={"text-4 mt-4 " + (darkTheme ? "text-white" : "")}>
            Disclaimer of Warranties:
            </h5>
            <p>
            Our website is provided on an "as is" basis, without any express or implied warranties. We do not guarantee that our website will be uninterrupted, error-free, or that any defects will be corrected.
            </p>
            
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditions;
