import React from "react";

const Disclaimer = ({ darkTheme }) => {
  return (
    <div
      id="disclaimer"
      className="modal fade"
      role="dialog"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-lg modal-dialog-centered"
        role="document"
      >
        <div
          className={
            "modal-content " + (darkTheme ? "bg-dark-2 text-light" : "")
          }
        >
          <div className="modal-header">
            <h5 className={"modal-title " + (darkTheme ? "text-white" : "")}>
              Copyright &amp; Disclaimer
            </h5>
            <button
              type="button"
              className={"btn-close " + (darkTheme ? "btn-close-white" : "")}
              data-bs-dismiss="modal"
              aria-label="Close"
            />
          </div>
          <div className="modal-body p-4">
            {/**
              Please note that the information provided on this website is intended for general informational purposes only and should not be considered as legal, financial, or professional advice. Any reliance on this information is solely at your own risk.

              Although we strive to ensure the accuracy, currency, and error-free nature of the information presented on this website, we do not guarantee or represent its complete accuracy. We also do not guarantee or represent that this website will always be available, accessible, or free from interruptions.

              The content on this website is subject to change without prior notice. We reserve the right to make any necessary changes, corrections, or improvements to the information, services, and products described on this website at any time.

              We shall not be liable for any direct, indirect, or consequential loss or damage, including loss of data or profits arising out of, or in connection with, the use of this website.

              This website may include links to other websites that are beyond our control. We do not have any control over the content, nature, and availability of those sites. The inclusion of any links does not imply a recommendation or endorsement of the views expressed within them.

              We make every effort to keep this website running smoothly. However, we are not responsible for any temporary unavailability due to technical issues beyond our control.

             */}
            <p className={"text-auto" + (darkTheme ? "text-white-50": "")}>
            Please note that the information provided on this website is intended for general informational purposes only and should not be considered as legal, financial, or professional advice. Any reliance on this information is solely at your own risk.
            </p>
           
            <p className={"text-auto" + (darkTheme ? "text-white-50" :"")}>
              Although we strive to ensure the accuracy, currency, and error-free nature of the information presented on this website, we do not guarantee or represent its complete accuracy. We also do not guarantee or represent that this website will always be available, accessible, or free from interruptions.
            </p>
            <p className={"text-auto" + (darkTheme ? "text-white-50" : "")}>
              The content on this website is subject to change without prior notice. We reserve the right to make any necessary changes, corrections, or improvements to the information, services, and products described on this website at any time.
              </p>
              <p className={"text-auto" + (darkTheme ? "text-white-50": "")}>
              We shall not be liable for any direct, indirect, or consequential loss or damage, including loss of data or profits arising out of, or in connection with, the use of this website.
              </p>
              <p className={"text-auto" + (darkTheme ? "text-white-50": "")}>
              This website may include links to other websites that are beyond our control. We do not have any control over the content, nature, and availability of those sites. The inclusion of any links does not imply a recommendation or endorsement of the views expressed within them.
              </p>
              <p className={"text-auto" + (darkTheme ? "text-white-50": "")}>
              We make every effort to keep this website running smoothly. However, we are not responsible for any temporary unavailability due to technical issues beyond our control.
              </p>              
              {/**
              <li>
                Persius interesset his et, Lisque persius interesset his et, in
                quot quidam persequeris vim, ad mea essent possim iriure.
              </li>
              <li>
                Quot quidam persequeris vim Quidam lisque persius interesset his
                et, Lisque persius interesset his et, in quot quidam persequeris
                vim, ad mea essent possim iriure.
              </li>
               */}
            {/**</div></div></div></ul>*/}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Disclaimer;
