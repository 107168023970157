import React from "react";

const Services = ({classicHeader, darkTheme }) => {
  // services details
  const services = [
    {
      name: "Brand & Marketing Communications:",
      desc: "I   help   clients   create   marketing   communications   strategy   and   digital footprint that ensures high visibility, brand acceptance and increased brand value.",
      icon: "fa-solid fa-arrows-to-dot",
    },

    /**
     * Leadership Development:
     */
    {
      name: "Leadership Development",
      desc: "Here, I facilitate trainings for business executives in leadership positions for peak performance, coach and mentor young professionals and develop individuals into great leaders to help them live a meaningful life.",
      icon: "fa-solid fa-book-open-reader",
    },
    /**
     * Mentorship
     */
    {
      name: "Mentorship",
      desc: "It has been one of my life’s assignments to ensure that I guide individuals into finding their path, achieving their goals and living their dreams in a way that makes life more meaningful.",
      icon: "fa-solid fa-hanukiah",
    },
    /**
     * Podcasting/Writing
     */
    {
      name: "Podcasting/Writing",
      desc: "In a bid to share the things I know, I started writing and this has helped me crystalize my thoughts and helped others gain insights to living a meaningful life. I also started podcasting to share my views on a wide range of issues and bring others to share their stories.",
      icon: "fa-solid fa-podcast",
    },
    /**
     * Public Speaking
     */
    {
      name: "Public Speaking",
      desc: "Over the years, I have been privileged and invited to speak to thousands of people. Upon becoming good at public speaking, I decided to train individuals who have the fear of public speaking.",
      icon: "fa-solid fa-user-ninja",
    },
    /**
     * 
     
    {
      name: "SEO Marketing",
      desc: "Lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure.",
      icon: "fas fa-bullhorn",
    },
    */
  ];

  return (
    <section
      id="services"
      className={"section " + (darkTheme ? "bg-dark-2" : "bg-light")}
    >
      <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
        {/* Heading */}
        <div className="position-relative d-flex text-center mb-5">
          <h2
            className={
              "text-24  text-uppercase fw-600 w-100 mb-0 " +
              (darkTheme ? "text-white-50  opacity-1" : "text-light  opacity-4")
            }
          >
            Services
          </h2>
          <p
            className={
              "text-9  fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
              (darkTheme ? "text-white" : "text-dark")
            }
          >
            What I Do?
            <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
          </p>
        </div>
        {/* Heading end*/}
        {/* content start */}
        <div className="row">
          <div className="col-lg-11 mx-auto">
            <div className="row">
              {services.length > 0 &&
                services.map((service, index) => (
                  <div className="col-md-6" key={index}>
                    <div className="featured-box style-3 mb-5">
                      <div
                        className={
                          "featured-box-icon text-primary  shadow-sm rounded " +
                          (darkTheme ? "bg-dark-1" : "bg-white")
                        }
                      >
                        <i className={service.icon} />
                      </div>
                      <h3 className={darkTheme ? "text-white" : ""}>
                        {service.name}
                      </h3>
                      <p
                        className={"text-justify mb-0 " + (darkTheme ? "text-white-50 text-justify" : "")}
                      >
                        {service.desc}
                      </p>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
        {/* content end */}
      </div>
    </section>
  );
};

export default Services;
