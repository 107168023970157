import React from "react";

/*import resumeFile from "../documents/resume.pdf";*/
const AboutUs = ({ classicHeader, darkTheme }) => {
  return (
    <section id="about" className={"section " + (darkTheme ? "bg-dark-1" : "")}>
      <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
        {/* Heading */}
        <div className="position-relative d-flex text-center mb-5">
          <h2
            className={
              "text-24  text-uppercase fw-600 w-100 mb-0 " +
              (darkTheme ? "text-muted opacity-1" : "text-light opacity-4")
            }
          >
            About Me
          </h2>
          <p
            className={
              "text-9 text-dark fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
              (darkTheme ? "text-white" : "text-dark")
            }
          >
            Get Familiar
            <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
          </p>
        </div>
        {/* Heading end*/}
        <div className="row gy-5">
          {/* About me content start */}
          <div className="col-lg-7 col-xl-8 text-center text-lg-start">
            <h2
              className={
                "text-7 fw-600 mb-3 " + (darkTheme ? "text-white" : "")
              }
            >
              I'm <span className="text-primary">Daniel OTABOR,</span> a Brand & Marketing 
              Communications professional
            </h2>
            <p className={"text-left" + (darkTheme ? "text-white-50" : "")}>
            I am a Nigerian Author and a Brand & Marketing Communications professional
            with over 6years experience.
            </p>
            <p className={"text-auto" + (darkTheme ? "text-white-50" : "")}>
            In 2021, I published and launched my book; 	Dear Leader	 -  a 365-day approach
            to leadership	.   The   book   was   written   to   help   individuals   understand   their
            leadership potential for national transformation. With the launch of the book, I
            started   an   annual   leadership   conference   tagged:   	The Leadership
            Responsibility for Nation Building.	  This   conference   has   successfully
            educated, empowered and equipped individuals who have gone on to become
            remarkable leaders in various sectors around the world.
            </p>
            <p className={"text-auto" + (darkTheme ? "text-white-50 text-justify" : "")}>
            As an avid reader of Chinua Achebe's books, I remember he said in one of his
            books,   "The trouble with Nigeria is simply and squarely a failure of
            leadership. There is nothing basically wrong with the Nigerian
            character. There is nothing wrong with the Nigerian land or climate or
            air or anything else.”	  Taking this profound assertion to heart, I decided to do
            something about the political leadership of Nigeria, and indeed, Africa. So, in
            2022,   I   Co-Founded  	RunForNigeria	;   an   independent   institution   that   is
            developing and deploying charismatic and competent leaders across political
            positions in Africa.
            </p>
          </div>
          {/* About me content end */}
          {/* about me personal detials start */}
          <div className="col-lg-5 col-xl-4">
            <div className="ps-lg-4">
              <ul
                className={
                  "list-style-2 " +
                  (darkTheme ? "list-style-light text-light" : "")
                }
              >
                <li>
                  <span className="fw-600 me-2">Name:</span>Daniel OTABOR
                </li>
                <li>
                  <span className="fw-600 me-2">Email:</span>
                  <a href="mailto:hi@danielotabor.com">hi@danielotabor.com</a>
                </li>
                <li>
                  <span className="fw-600 me-2">Phone Number:</span>
                  <a href="tel:+2348024306581">+2348024306581</a>
                </li>
                <li className="border-0">
                  <span className="fw-600 me-2">From:</span>Lagos,
                  Nigeria
                </li>
              </ul>
              {/**
              <a
                href="#"
                download
                className="btn btn-primary rounded-pill"
              >
                Download CV
              </a> */}
              
            </div>
          </div>
          {/* about me personal details end */}
        </div>
        {/* projects rewards counting start */}
        {/**
        <div
          className={
            "brands-grid separator-border mt-5 " +
            (darkTheme ? "separator-border-light" : "")
          }
        >
          <div className="row">
            <div className="col-6 col-md-3">
              <div className="featured-box text-center">
                <h4
                  className={
                    "text-12  mb-0 " +
                    (darkTheme ? "text-white-50" : "text-muted")
                  }
                >
                  <span>10</span>+
                </h4>
                <p className={"mb-0 " + (darkTheme ? "text-light" : "")}>
                  Years Experiance
                </p>
              </div>
            </div>
            <div className="col-6 col-md-3">
              <div className="featured-box text-center">
                <h4
                  className={
                    "text-12  mb-0 " +
                    (darkTheme ? "text-white-50" : "text-muted")
                  }
                >
                  <span>250</span>+
                </h4>
                <p className={"mb-0 " + (darkTheme ? "text-light" : "")}>
                  Happy Clients
                </p>
              </div>
            </div>
            <div className="col-6 col-md-3">
              <div className="featured-box text-center">
                <h4
                  className={
                    "text-12  mb-0 " +
                    (darkTheme ? "text-white-50" : "text-muted")
                  }
                >
                  <span>650</span>+
                </h4>
                <p className={"mb-0 " + (darkTheme ? "text-light" : "")}>
                  Projects Done
                </p>
              </div>
            </div>
            <div className="col-6 col-md-3">
              <div className="featured-box text-center">
                <h4
                  className={
                    "text-12  mb-0 " +
                    (darkTheme ? "text-white-50" : "text-muted")
                  }
                >
                  <span>38</span>
                </h4>
                <p className={"mb-0 " + (darkTheme ? "text-light" : "")}>
                  Get Awards
                </p>
              </div>
            </div>
          </div>
        </div>
        */}
        {/* projects rewards counting end */}
      </div>
    </section>
  );
};

export default AboutUs;
